/* You can add global styles to this file, and also import other style files */

@import 'defs';

html, body {
  height: 100vh;
}

img.emoji {
  height: 1em;
  width: 1em;
  margin: 0 .05em 0 .1em;
  vertical-align: -0.1em;
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
//@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";

//@import 'node_modules/bootstrap/scss/bootstrap';

.btn-navigation {
  border: 0 none;
  border-radius: 0;
  box-shadow: none !important;
}

.btn-navigation.focus, .btn-navigation:focus {
  color: $white;
  background-color: $dark;
  border-color: $dark;
}

.btn-navigation.disabled, .btn-navigation:disabled {
  color: $dark;
  background-color: transparent;
  border-color: transparent;
}

.highlightable-inside {
  outline: 0 none;
}

.highlightable-inside:focus {
  outline: 2px solid black;
  outline-offset: -2px;
  outline-color: -webkit-focus-ring-color;
}
